<template>
    <div class="background">
        <img src="@/assets/P-circle-09.svg" alt="circle" :class="page + ' outline circle'">
        <img src="@/assets/O-circle-07.svg" alt="circle" :class="page + ' solid circle'">
        <img src="@/assets/G-circle-08.svg" alt="circle" :class="page + ' solid2 circle'">
    </div>
</template>

<script>
    export default {
        props: {
            page: { type: String, default: "checkout" },
        }
    }
</script>

<style lang="scss" scoped>
.background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}
.circle {
    position: absolute;
    opacity: 0.5;
}

// Checkout page
.checkout.solid {
    width: 200px;
    height: 200px;
    top: 55vh;
    left: -100px;
}
.checkout.solid2 {
    display: none;
}
.checkout.outline {
    width: 250px;
    height: 250px;
    top: 5vh;
    right: -100px;
}

// Contact form page
.contact.solid {
    width: 300px;
    height: 300px;
    top: 6vh;
    right: -100px;
}
.contact.solid2 {
    width: 125px;
    height: 125px;
    top: 35vh;
    left: 0px;
}
.contact.outline {
    width: 200px;
    height: 200px;
    top: 50vh;
    left: -100px;
}

// Subscriptions page
.subscriptions.solid {
    width: 250px;
    height: 250px;
    top: 5vh;
    right: -100px;
}
.subscriptions.solid2 {
    width: 125px;
    height: 125px;
    bottom: 15vh;
    right: -70px;
}
.subscriptions.outline {
    width: 200px;
    height: 200px;
    top: 55vh;
    left: -100px;
}

// Profile page
.profile.solid {
    width: 300px;
    height: 300px;
    top: 6vh;
    right: -100px;
}
.profile.solid2 {
    width: 125px;
    height: 125px;
    top: 35vh;
    left: 0px;
}
.profile.outline {
    width: 200px;
    height: 200px;
    top: 50vh;
    left: -100px;
}

// Not found page
.notFound.solid {
    width: 200px;
    height: 200px;
    top: 55vh;
    right: -100px;
}
.notFound.solid2 {
    width: 125px;
    height: 125px;
    top: 25vh;
    right: -30px;
}
.notFound.outline {
    width: 250px;
    height: 250px;
    top: 7vh;
    left: -100px;
}

</style>
