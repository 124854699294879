<template>
    <div>
        <div v-if="editable">
            <v-form ref="addressForm">
                <v-text-field v-model="local.line1" label="Line 1" outlined background-color="white" :rules="line1Rules" @input="updateAddress" :dense="dense"></v-text-field>
                <v-text-field v-model="local.line2" label="Line 2 (optional)" outlined background-color="white" @input="updateAddress" :dense="dense"></v-text-field>

                <v-row no-gutters class="mt-2">
                    <v-col cols="6">
                        <v-text-field v-model="local.city" label="City" outlined background-color="white" :rules="cityRules" class="txt-left" @input="updateAddress" :dense="dense"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="local.state" label="State" outlined background-color="white" v-mask="'AA'" :rules="stateRules" class="txt-middle upper" @input="updateAddress" :dense="dense"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="local.zipCode" label="Zip Code" outlined background-color="white" v-mask="'#####'" :rules="zipCodeRules" class="txt-right" @input="updateAddress" :dense="dense"></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </div>

        <div v-else>
            <address>
                <div v-if="local.name != undefined && local.name != null && local.name.length > 0" class="subtitle-2">{{ local.name }}</div>
                <div>{{ local.line1}}</div>
                <div v-if="local.line2 != undefined && local.line2 != null && local.line2.length > 0">{{ local.line2 }}</div>
                <div>{{ local.city }} {{ local.state }}<span v-if="local.zipCode != null && local.zipCode.length > 0">,</span> {{ local.zipCode }}</div>
            </address>
        </div>
    </div>
</template>

<script>
import { _st } from '../softech'
import { mask } from 'vue-the-mask'
export default {
    props: {
        value       : { type: Object, default() { return {}; } },
        editable    : { type: Boolean, default: false },
        dense       : { type: Boolean, default: false },
    },
    data() {
        return {
            local           : {
                name    : this.value?.name,
                line1   : this.value?.line1,
                line2   : this.value?.line2,
                city    : this.value?.city,
                state   : this.value?.state,
                zipCode : this.value?.zipCode
            },

            line1Rules      : [
                v => !!v || 'Line 1 is required',
            ],
            cityRules       : [
                v => !!v || 'City is required',
            ],
            stateRules      : [
                v => !!v || 'State is required',
                v => /[a-zA-z]{2}/.test(v) || 'State must be valid'
            ],
            zipCodeRules    : [
                v => !!v || 'Zip code is required',
                v => /[0-9]{5}/.test(v) || 'Zip code must be valid'
            ]
        }
    },
    watch: {
        value( v ) {
            if( _st.isNU( v ) )
                return;

            this.local.name = v.name;
            this.local.line1 = v.line1;
            this.local.line2 = v.line2;
            this.local.city = v.city;
            this.local.state = v.state;
            this.local.zipCode = v.zipCode;
        }
    },
    methods: {
        updateAddress() {
            this.$emit( 'input', {
                line1: this.local.line1,
                line2: this.local.line2,
                city: this.local.city,
                state: this.local.state,
                zipCode: this.local.zipCode
            } );
        },
        isValid() {
            return this.$refs.addressForm.validate();
        },
        resetValidation() {
            this.$refs.addressForm.resetValidation();
        },
        reset() {
            this.$refs.addressForm.reset();
        }
    },
    directives: {
        mask
    }
}
</script>

<style lang="scss" scoped>
.txt-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.txt-middle {
    border-radius: 0;
}
.txt-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.upper input {
    text-transform: uppercase;
}
</style>